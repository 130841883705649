export default function (context) {
	const utcTime = (date) => {
		return context.$moment(date || new Date()).utc().format('YYYY-MM-DD HH:mm:ss');
	};

	const setAgentDomainHeader = (config) => {
		if (process.client) {
			// production
			config.headers.common['agent-domain'] = window?.location?.host
			// testing purposes
			// config.headers.common['agent-domain'] = 'wing90.com'
		}
	};

	const setDateParamsToUTC = (config) => {
		const params = { ...config.params } || {};
		if (params.start_date) params.start_date = utcTime(params.start_date);
		if (params.end_date) params.end_date = utcTime(params.end_date);
		config.params = params;
	};

	context.$axios.onRequest((config) => {
		setAgentDomainHeader(config);
		setDateParamsToUTC(config);
		if (process.server) {
			config.headers.origin = `https://${context.app.$config.DOMAIN}`;
		}
		config.baseURL = context.app.$config.API_DOMAIN;
		if (context.$cookie.get('token')) {
			config.headers.common.Authorization = `${context.$cookie.get('token')}`;
		}
	});

	context.$axios.interceptors.response.use(
		(response) => {
			const isJSON = response.headers['content-type'].includes('application/json');
			return isJSON ? response : Promise.reject(response);
		},
		(error) => {
			return retryRequest(error);
		}
	);

	const retryRequest = async (error) => {
		const { config, message } = error;
		if (!config || !config.retry) return Promise.reject(error);

		if (message.includes("timeout") || message.includes("Network Error")) {
			config.retry -= 1;
			await new Promise((resolve) => setTimeout(resolve, config.retryDelay || 1000));
			return context.$axios(config);
		}
		return Promise.reject(error);
	};

	context.$axios.onError((error) => {
		handleError(error);
	});

	const handleError = (error) => {
		if (process.client) {
			context.$toast?.clear();
			if (error?.response && error?.response?.data?.message) {
				const message = context.store.getters['themes/language-string/localeLanguage'][error.response.data.message];
				showToastMessage(message || error.response.data.message);
			} else if (!navigator.onLine) {
				showToastMessage(context.tl("pleaseCheckInternetConnection"));
			} else {
				showToastMessage(context.tl("pleaseTryAgainAfterSometime"));
			}

			if (error?.response?.status === 401) {
				context.store.dispatch('player/logout');
			}
		}
	};

	const showToastMessage = (message) => {
		context.$toast?.error(message);
	};
}
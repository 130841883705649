import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { globalVar } from '~/utils/globalVar'
const currencyJs = require('currency.js')

if (!Vue.__global_mixin_) {
	Vue.__global_mixin_ = true
	Vue.mixin({
		layout(context) {
			const appTheme = context.$config.THEME
			const themes = globalVar.themeList
			if (appTheme && themes.includes(appTheme)) {
				return appTheme
			}
			return globalVar.themes.theme2.value
		},
		data() {
			return {
				host: '',
			}
		},
		computed: {
			...mapState({
				globalProfileBalanceSocket: (state) =>
					state.player.totalBalanceFromSocket,
				totalBalance: (state) => state.player.totalBalance,
				globalProfileBalance: (state) => state.player.player.balance,
				language: (state) => state.settings.locale, // this language and systemLocale have the same value, i'll remove one later
				systemLocale: (state) => state.settings.locale,
				globalIsMobileOnly: (state) => state.themes.settings.isMobile, // Please never use this in any page
				theme2LanguageString: (state) =>
					state.themes['language-string'].languageStrings,
				cancelToken: (state) => state.settings.cancelToken,
				// isInternational: (state) => state.themes.settings.isInternational,
				defaultSupportedLanguage: (state) => state.settings.defaultSupportedLanguage,
				supportCountryAndLanguage: (state) => state.settings.supportCountryAndLanguage,
				globalDefaultCurrency: (state) => state.settings.defaultCurrency,
				globalPlayerInfo: (state) => state.player.player,
			}),
			isInternational() {
				return ~~this.$cookie.get("wl_type") === 1
			},
			...mapGetters({
				loadImageError: 'themes/settings/loadImageError',
				theme2Languages: 'themes/settings/language',
				faviconIcon: 'cms/faviconIcon',
				globalBankGetterList: 'banks/banks',
			}),
			noImageUri() {
				return '/no-image.jpeg'
			},
			isMain() {
				const domainType = this.$cookie.get('dT')
				if (domainType?.toLowerCase() === 'main') return true;
				return false;
			},
			isCslvault() {
				return (
					this.$config.DOMAIN === 'cslvault.com' ||
					this.$config.DOMAIN === 'cslzone.com' ||
					this.$config.PHASE === 'theme2-phase2'
				)
			},
			isCSLVault() {
				return this.$config.DOMAIN === 'cslvault.com'
			},
			isPlayGamePage() {
				return this.$config.DOMAIN === 'cslvault.com' ||
					this.$config.DOMAIN === 'cslzone.com'
					? false
					: this.$route.path === '/play-game'
			},
			globalWebsiteDomainName() {
				if (process.client) {
					const domain = window?.location?.hostname || this.$config.DOMAIN
					return domain
				}
				return this.$config.DOMAIN
			},
			globalPlayerId() {
				return this.globalPlayerInfo?.player_id || this.$cookie?.get('player')?.player_id
			},
			globalInviteAndEarnLink() {
				return `https://${this.globalWebsiteDomainName}/u/${this.globalPlayerId}`
			},
			globalBanksList() {
				const banksListFormat = this.globalBankGetterList.map((el) => {
					let name = ''
					if (el.display_name) {
						if (el.display_name[this.systemLocale]) {
							name = el.display_name[this.systemLocale]
						} else if (el.display_name['en-US']) {
							name = el.display_name['en-US']
						} else {
							const firstBankExistLocale = Object.keys(
								el.display_name
							)[0]
							if (el.display_name[firstBankExistLocale]) {
								name = el.display_name[firstBankExistLocale]
							}
						}
					}
					return {
						...el,
						name,
					}
				})
				return banksListFormat
			},
			bankListAndManual() {
				return [
					{
						value: 1,
						text: this.tl('bankList'),
					},
					{
						value: 2,
						text: this.tl('manuallyInputBank'),
					},
				]
			},
		},
		mounted() {
			this.host = window.location.host
			if (this.$cookie?.get('locale')) {
				this.$store.dispatch('settings/locale', this.$cookie?.get('locale'))
			}
		},
		methods: {
			goBack() {
				return this.$router.go(-1)
			},
			async logout() {
				this.$store.dispatch('settings/toggleIsLogin', false)
				await this.$store.dispatch('player/logout')
				await this.$store.dispatch('settings/supportCountryAndLanguage', { fetch: true })
			},
			copy(content, success = true) {
				if (navigator.clipboard) {
					navigator.clipboard.writeText(content)
				} else {
					const dummy = document.createElement('textarea')
					dummy.value = content
					dummy.style.top = '0'
					dummy.style.left = '0'
					dummy.style.position = 'fixed'
					dummy.style.zIndex = -1
					document.body.appendChild(dummy)
					dummy.focus()
					dummy.select()
					dummy.setSelectionRange(0, 99999)
					document.execCommand('copy')
					document.body.removeChild(dummy)
				}
				if (success) {
					this.$toast.success(this.tl('copiedToClipboard'))
				} else {
					this.$toast.show(this.tl('contentCopied'))
				}
			},
			checkCapitalLetter(ch) {
				return ch.charCodeAt() >= 65 && ch.charCodeAt() <= 90
			},
			customToFixedTrunc(x, n) {
				const v = (typeof x === 'string' ? x : x.toString()).split('.')
				if (n <= 0) return v[0]
				let f = v[1] || ''
				if (f.length > n) return `${v[0]}.${f.substr(0, n)}`
				while (f.length < n) f += '0'
				return `${v[0]}.${f}`
			},
			globalExchangeRate(from, rate, to = null, round = true) {
				if (from === 'LAK' || (from === 'THB' && to === 'USD')) {
					if (!round) {
						return this.customToFixedTrunc(1 / parseFloat(rate), 2)
					}
					return (1 / parseFloat(rate)).toFixed(2)
				}
				return (1 * rate).toFixed(2)
			},
			globalPlayerSocketBalance() {
				return (
					this.globalProfileBalanceSocket ||
					this.totalBalance ||
					this.globalProfileBalance ||
					'0'
				)
			},
			tl(code) {
				return this.theme2LanguageString[code] || code
			},
			momentDate(date) {
				if (!date) {
					return '-'
				}
				return this.$moment(date).local().format('DD-MM-YYYY HH:mm:ss')
			},
			playGame(game) {
				if (game === null || game === undefined) return
				if (!this.$cookie.get('token')) {
					this.$store.dispatch('settings/toggleIsLogin')
					this.$router.push('/login')
					return
				}
				const {
					game_id: gameId,
					game_provider_uuid: gameProviderId,
					game_type_id: gameTypeId,
					product_id: productId,
					portfolio_uuid: portfolioUuid,
				} = game

				let link = `/play-game?platform=${this.isMobileDetected() || this.$vuetify.breakpoint.smAndDown
					? 'phone'
					: 'desktop'
					}&icon=${this.getLocaleLang(this.faviconIcon?.image_path)}`

				if (gameId) link += `&game_uuid=${gameId}`
				if (gameTypeId) link += `&game_type_uuid=${gameTypeId}`
				if (gameProviderId) link += `&game_provider_uuid=${gameProviderId}`
				if (productId) link += `&product_id=${productId}`
				if (portfolioUuid) link += `&portfolio_uuid=${portfolioUuid}`
				// window.open(link);
				// console.log("link", link)
				this.$router.push({ path: link })
			},
			formatCurrencyJS(value, removeDecimal = false, precision = 2) {
				if (!value) value = 0
				const response = currencyJs(parseFloat(value), {
					separator: ',',
					symbol: '',
					precision
				}).format()
				if (removeDecimal) {
					// This precision is for exchange rates purposes only
					if (precision === 3)
						return response.replace('.000', '')
					return response.replace('.00', '')
				}
				return response
			},
			isMobileOnly() {
				return this.globalIsMobileOnly
			},
			cmsImageLink({ path, code }) {
				function camelToSnakeCase(str) {
					if (!str) return ''
					const snakeCase = str.replace(
						/[A-Z]/g,
						(letter) => `_${letter.toLowerCase()}`
					)
					return snakeCase.substring(1, snakeCase.length)
				}
				if (path) {
					const _url = `${this.$config.BUCKET}/${camelToSnakeCase(
						code
					)}/${path}`
					return _url
				}
			},
			agentImageLink(path) {
				const _url = `${this.$config.AGENT_BUCKET}/${path}`
				return _url
			},
			openNewBrowserTab(inputUrl, item = null) {
				if (item) {
					if (parseInt(item.link_type) === 2) {
						const {
							game_id: gameId,
							game_provider_id: gameProviderId,
							game_type_id: gameTypeId,
						} = item
						if (gameId && gameProviderId && gameTypeId) {
							this.playGame({
								game_id: gameId,
								game_provider_uuid: gameProviderId,
								game_type_id: gameTypeId,
							})
						}
						return null
					}
				}
				const url = inputUrl?.trim()
				if (!url) return null
				if (url.includes(this.$config.DOMAIN)) {
					window.location.href = url;
				} else {
					window.open(
						!url.toLowerCase().startsWith('http') ? 'http://' + url : url,
						'_blank'
					)
				}
			},
			async checkIframeSupported(item) {

				if (item === null || item === undefined) return
				if (!this.$cookie.get('token')) {
					this.$store.dispatch('settings/toggleIsLogin')
					this.$router.push('/login')
					return
				}
				const {
					game_id: gameId,
					game_provider_id: gameProviderId,
					game_type_id: gameTypeId,
					product_id: productId,
					portfolio_uuid: portfolioUuid,
				} = item

				let link = `/play-game?platform=${this.isMobileDetected() || this.$vuetify.breakpoint.smAndDown
					? 'phone'
					: 'desktop'
					}&icon=${this.getLocaleLang(this.faviconIcon?.image_path)}`

				if (gameId) link += `&game_uuid=${gameId}`
				if (gameTypeId) link += `&game_type_uuid=${gameTypeId}`
				if (gameProviderId) link += `&game_provider_uuid=${gameProviderId}`
				if (productId) link += `&product_id=${productId}`
				if (portfolioUuid) link += `&portfolio_uuid=${portfolioUuid}`
				// console.log({ link })
				// check
				const param = {}
				param.platform = this.isMobileDetected() || this.$vuetify.breakpoint.smAndDown ? 'phone' : 'desktop'
				if (parseInt(item.link_type) === 2) {
					if (gameId) param.game_uuid = gameId
					if (gameTypeId) param.game_type_uuid = gameTypeId
					if (gameProviderId) param.game_provider_uuid = gameProviderId
					if (productId) param.product_id = productId
					if (portfolioUuid) param.portfolio_uuid = portfolioUuid
				}
				const res = await this.$store.dispatch(
					'games/playGame',
					{
						...param,
					},
					false
				)

				if (res.status && res.url) {
					if (res.isSupportIframe) {
						this.$router.push({ path: link })
					} else {
						// New Changes, open non-support-iframe game in the current browser tab
						window.location.href = !res.url.toLowerCase().startsWith('http')
							? 'http://' + res.url
							: res.url
					}
				}
			},
			checkGameDisableOrMaintenance(item) {
				return (
					(this.parseIntNumber(item.link_type) === 2 && !item.game_setting) ||
					(this.parseIntNumber(item.link_type) === 2 &&
						item.game_setting &&
						(!item.game_setting.is_active ||
							item.game_setting.is_maintenance ||
							(item.game_setting.game_type_info &&
								(!item.game_setting.game_type_info.is_active ||
									item.game_setting.game_type_info.is_maintenance))))
				)
			},
			getGameDisableOrMaintenanceText(item) {
				return (this.parseIntNumber(item.link_type) === 2 &&
					!item.game_setting) ||
					(this.parseIntNumber(item.link_type) === 2 &&
						item.game_setting &&
						(!item.game_setting.is_active ||
							(item.game_setting.game_type_info &&
								!item.game_setting.game_type_info.is_active)))
					? this.tl('gameDisabled')
					: this.tl('underMaintenance')
			},
			parseIntNumber(val) {
				try {
					return Number.parseInt(val)
				} catch (error) {
					return false
				}
			},
			// show the current locale text
			// if current locale not exist, show english instead
			getLocaleLang(item, forceLocale = false) {
				if (!item) return null
				if (forceLocale) {
					return item[this.systemLocale]
				}
				if (item[this.systemLocale]) return item[this.systemLocale]
				else return item['en-US']
			},
			// New flag logic
			getCurrentLocaleIcon(localeCode) {
				const currentLocaleData = this.theme2Languages.find(
					(el) => el.language === localeCode
				)
				return currentLocaleData?.icon
			},
			getVisibleItems(items) {
				if (!items || typeof items !== 'object' || !items[0]) return []
				return items?.filter((item) => item?.is_visible)
			},
			getYoutubeVideoId(link) {
				if (!link) return ''
				try {
					const youtubeVideoId = link
						?.replace('youtu.be/', 'youtube.com/watch?v=')
						?.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)
						?.pop()
					return youtubeVideoId
				} catch (error) {
					return ''
				}
			},
			// This function is to detect Physical Device for playing games, don't touch it
			isMobileDetected() {
				let check = false
				if (process.client) {
					const a = navigator?.userAgent || navigator?.vendor || window?.opera
					if (
						/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
							a
						) ||
						/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
							a.substr(0, 4)
						)
					)
						check = true
				}
				return check
			},
			doCancelAxiosRequest() {
				for (const key in this.cancelToken) {
					if (this.cancelToken[key]) {
						this.cancelToken[key].cancel('')
						delete this.cancelToken[key]
					}
				}
			},
			imageLoadingErrorTrick(id) {
				const loadError = {
					...this.loadImageError,
				}
				loadError[id] = true
				this.$store.commit('themes/settings/setLoadImageError', loadError)
			},
			isLaoWhiteLabel() {
				if (~~this.$cookie.get('player')?.wl_type === 2) {
					return true;
				}
				return false
			},
			customFormatNumber(number) {
				const parts = number.toString().split('.')
				parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				const value = parts.join('.')
				return value
			},
			async globalBrowserDefaultLanguage() {
				const countryCode = this.$cookie.get("country_code")
				const data = this.supportCountryAndLanguage.find((el) => el.country_code === countryCode)
				const supportedLanguages = data?.supported_languages

				if (supportedLanguages.length > 0) {
					// Check if the browser language is matched to one of the supported languages
					const browserLanguage = navigator?.language
					const browserPrefixLanguageTag = browserLanguage?.substring(0, 2)
					// const browserPrefixLanguageTag = 'zh'

					// Find the language which starting with the same browser Prefix Language Tag
					let findMatchBrowserLanguage = null;
					if (browserPrefixLanguageTag) {
						findMatchBrowserLanguage = supportedLanguages.find((el) => {
							if (el.language.includes(browserPrefixLanguageTag) && el.is_active) {
								return true;
							}
							return false;
						})
					}
					// Find the browser Prefix Language is match, then use only browser language
					if (findMatchBrowserLanguage) {
						this.$cookie.set('locale', findMatchBrowserLanguage.language)
						this.$store.commit('settings/locale', findMatchBrowserLanguage.language)
						await this.$store.dispatch(
							'themes/language-string/fetchLanguageStrings',
							findMatchBrowserLanguage.language
						)
					} else {
						const findIsActiveLanguage = supportedLanguages.find(el => el.is_active && el.is_default)
						if (findIsActiveLanguage) {
							this.$cookie.set('locale', findIsActiveLanguage.language)
							this.$store.commit('settings/locale', findIsActiveLanguage.language)
							await this.$store.dispatch(
								'themes/language-string/fetchLanguageStrings',
								findIsActiveLanguage.language
							)
						} else {
							this.$cookie.set('locale', supportedLanguages[0].language)
							this.$store.commit('settings/locale', supportedLanguages[0].language)
							await this.$store.dispatch(
								'themes/language-string/fetchLanguageStrings',
								supportedLanguages[0].language
							)
						}
					}
					await this.$store.dispatch('cms/cms', {})
				}
			},
			isEqual(obj1, obj2) {
				if (obj1 === obj2) {
					return true
				}

				if (obj1 == null || obj2 == null) {
					return false
				}

				if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
					return false
				}

				const keys1 = Object.keys(obj1)
				const keys2 = Object.keys(obj2)

				if (keys1.length !== keys2.length) {
					return false
				}

				for (const key of keys1) {
					if (!keys2.includes(key)) {
						return false
					}

					if (
						typeof obj1[key] === 'object' &&
						typeof obj2[key] === 'object'
					) {
						if (!this.isEqual(obj1[key], obj2[key])) {
							return false
						}
					} else if (obj1[key] !== obj2[key]) {
						return false
					}
				}
				return true
			},
		},
	})
}
